// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-1-js": () => import("./../../../src/pages/project-1.js" /* webpackChunkName: "component---src-pages-project-1-js" */),
  "component---src-pages-project-3-js": () => import("./../../../src/pages/project-3.js" /* webpackChunkName: "component---src-pages-project-3-js" */),
  "component---src-pages-project-4-js": () => import("./../../../src/pages/project-4.js" /* webpackChunkName: "component---src-pages-project-4-js" */),
  "component---src-pages-project-5-js": () => import("./../../../src/pages/project-5.js" /* webpackChunkName: "component---src-pages-project-5-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */)
}

